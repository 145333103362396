<template>
  <div>
    <HomeCardList />
  </div>
</template>

<script>
import HomeCardList from "../components/HomeCardList.vue";

export default {
  name: "Home",
  components: {
    HomeCardList
  },
  data() {
    return {};
  }
};
</script>

<style></style>
