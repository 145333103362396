<template>
  <div class="card">
    <div class="card-header">
      <span style="float: left;">
        {{ cardHeaderText }}
      </span>
      <span class="text-muted" style="float: right;">
        {{ cardHeaderDateText }}
      </span>
    </div>
    <img class="card-img-top" :src="cardImgSrc" />
    <div class="card-body">
      <h5 class="card-title">{{ cardTitleText }}</h5>
      <p class="card-text">
        {{ cardBodyText }}
      </p>
    </div>
    <div class="card-footer">
    {{ cardFooterText }}
  </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    cardHeaderText: String, // Username
    cardHeaderDateText: String, // Date
    cardImgSrc: String, // Image
    cardTitleText: String, // Make and Model
    cardBodyText: String, // Description
    cardFooterText: String // Tags
  }
};
</script>

<style scoped>
  .card {
    width: 24.97rem;
    margin: 10px;
  }
.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: contain;
}
</style>
