<template>
  <div>
    <br /><br />
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h1 class="display-4" style="margin-left: 20%;">Create Post</h1>
        <br /><br />
        <form v-on:submit.prevent="submit">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="make"
              v-model="make"
              placeholder="Make"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="model"
              v-model="model"
              placeholder="Model"
            />
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder="Description"
              id="description"
              style="height: 100px; margin-bottom: 15px;"
              v-model="description"
            ></textarea>
          </div>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="tags"
              placeholder="Add Tags (Maximum of 5)"
              v-model="newTag"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-primary" type="button" @click="addTag">
                Add Tag
              </button>
            </div>
          </div>
          <p v-if="tags.length > 0" style="margin-top: 15px;">
              Tags: {{ tags.toString() }}
            </p>
          <div class="form-group">
            <!-- <amplify-photo-picker v-bind:photoPickerConfig="photoPickerConfig"></amplify-photo-picker> -->
            <amplify-s3-image-picker />
            <!-- <input type="file" id="file" ref="myFiles" v-on:change="GrabFile" /> -->
          </div>
          <button type="submit" class="btn btn-primary" v-on:click="Submit">
            Post
          </button>
        </form>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AmplifyEventBus } from 'aws-amplify-vue';

export default {
  name: "post",
  components: {},
  props: {
    //path: ""
  },
  data() {
    return {
      make: "",
      model: "",
      user: "",
      description: "",
      newTag: "",
      tags: [],
      files: null,
    };
  },
  mounted() {
    AmplifyEventBus.$on('fileUpload', img => {
      console.log(img);
    })
  },
  methods: {
    async Submit() {
      if (
        !this.make ||
        !this.model ||
        !this.files ||
        !this.description ||
        !this.tags
      ) {
        alert("All fields are required");
        return;
      }

      const formData = new FormData();
      formData.append("make", this.make);
      formData.append("model", this.model);
      formData.append("description", this.description);
      formData.append("image", this.files, this.files);
      formData.append("user", this.$store.state.currentUser.username);
      let tagName = "";
      for (let i = 0; i < this.tags.length; i++) {
        tagName = "tag" + (i + 1);
        formData.append(tagName, this.tags[i]);
      }

      formData.append("tagLength", this.tags.length);
      await axios
        .post("http://localhost:3000/api/posts", formData)
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GrabFile() {
      this.files = this.$refs.myFiles.files[0];
    },
    addTag() {
      if (this.newTag == "") {
        alert("Tag field cannot be empty");
        return;
      }
      if (this.tags.length == 5) {
        alert("A post can only contain up to 5 tags");
        this.newTag = "";
        return;
      }
      this.tags.push(this.newTag.toUpperCase());
      this.newTag = "";
    },
    submit() {},
  },
};
</script>

<style scoped></style>
