<template>
  <div id="app">
    <div id="content-wrap">
      <div v-show="checkPage()">
        <TheNavbar />
      </div>
    <router-view />
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from './components/TheNavbar';
import TheFooter from './components/TheFooter';
export default {
  name: "App",
  components: {
    TheNavbar,
    TheFooter
  },
  methods: {
    checkPage() {
      if (this.$route.name != "Auth") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
#app {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
#content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}
</style>
