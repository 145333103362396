<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <h3 class="navbar-brand">
        Gear Gallery
        <small class="text-muted">Where the Gear Nerds Go™</small>
      </h3>
      <span class="navbar-text" style="padding-left: 5px; margin-right: 10px;">
        |
      </span>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li v-if="signedIn" class="nav-item active">
            <router-link class="nav-link" to="/post">Post</router-link>
          </li>
          <li v-if="signedIn" class="nav-item active">
            <router-link class="nav-link" to="/profile">Account</router-link>
          </li>
        </ul>
        <div v-if="!signedIn">
          <form class="form-inline my-2 my-lg-0">
            <router-link
              class="btn btn-outline-primary"
              to="/auth"
              style="margin-right: 10px;"
              >Login or Register</router-link
            >
          </form>
        </div>
        <div v-else>
          <form class="form-inline my-2 my-lg-0">
            <span v-bind:value="user" class="navbar-text" style="margin-right: 15px;">Hello, {{ user }}</span>
            <amplify-sign-out></amplify-sign-out>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import { AmplifyEventBus } from 'aws-amplify-vue';
import { mapState } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
       user: null
    };
  },
  computed: {
    ...mapState(["signedIn"])
  },
  methods: {
    async getUser() {
      return await Auth.currentUserInfo();
    }
  },
  async created() {
    this.user = await Auth.currentUserInfo();
    if (this.user) {
      this.$store.dispatch("signIn");
      this.user = this.user.attributes.email;
    }
  },
  mounted() {
    AmplifyEventBus.$on('authState', info => {
      if (info == "signedOut") {
      this.$store.dispatch("signOut");
      this.user = null;
      } else {
        this.$store.dispatch("signIn");
        this.getUser().then(result => {
          this.user = result.attributes.email;
        })
      }
      if (this.$route.name != "Home") {
        this.$router.push({ name: "Home" });
      }
    });
  }
};
</script>

<style scoped>
.btn .btn-outline-success {
  color: #ff0000;
  border-color: #ff0000;
}
</style>
