<template>
  <div class="row">
    <div class="col-sm-.45"></div>
    <div class="col-sm-9.1">
      <div class="card-deck">
        <div v-for="post in posts" :key="post.id">
          <Card
            :card-header-text="post.user"
            :card-header-date-text="post.date"
            :card-img-src="post.image"
            :card-title-text="post.make + ' ' + post.model"
            :card-body-text="post.description"
            :card-footer-text="'Tags: ' + createTags(post.tags)"
          />
          <button type="button" class="btn btn-danger" style="margin-left: 10px" @click="deletePost(post.id)">Delete Post</button>
        </div>
      </div>
    </div>
    <div class="col-sm-.45"></div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "./Card";

export default {
  name: "ProfileCardList",
  components: {
    Card
  },
  data() {
    return {
      posts: []
    };
  },
  async mounted() {
    this.loadPosts();
  },
  methods: {
    createTags(tags) {
      let tagString = "";
      
      tags.forEach(tag => {
         tagString += tag + ', '
      });
      tagString = tagString.slice(0, tagString.length - 2);
    
      return tagString;
    },
    async deletePost(postID) {
        const answer = window.confirm('Are you sure you want to delete this post?');
        if (answer) {
            const urlString = "http://localhost:3000/api/posts/delete_by_id/" + postID;
            console.log(urlString)
            await axios.delete(urlString)
            .then(response => {
                console.log(response)
                alert('Post deleted')
                this.posts = [];
                this.loadPosts();
                this.$forceUpdate();
                return;
            })
            .catch(err => {
                console.log(err);
                return;
            })
        } else {
            return;
        }
    },
    async loadPosts() {
        const profile = JSON.parse(window.sessionStorage.currentUser)
        const getURL = "http://localhost:3000/api/posts/" + profile.username.toString();
        await axios
        .get(getURL)
        .then((response) => {
            for (let i = 0; i < response.data.posts.length; i++) {
                this.posts[i] = response.data.posts[i];
                this.posts[i].image =
                "https://geargallery-uploads.s3.amazonaws.com/" + response.data.posts[i].image;
            }
            return;
      })
      .catch((err) => console.log(err));
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped></style>
