import Vue from "vue";
import VueRouter from "vue-router";
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import Register from '../views/Register.vue';
import Post from '../views/Post.vue';
import Profile from '../views/Profile.vue';
import Auth from '../views/Auth.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/post',
        name: 'Post',
        component: Post
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth
    }
]

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
