<template>
  <div class="container">
    <br /><br />
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h1 class="display-4" style="margin-left: 5%;">Gear Gallery Register</h1>
        <br /><br />
        <form v-on:submit.prevent="submit">
            <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="firstName"
              v-model="firstName"
              required
              aria-describedby="usernameHelp"
              placeholder="First Name"
            />
            </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="Last Name"
              v-model="lastName"
              required
              aria-describedby="usernameHelp"
              placeholder="Last Name"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="Username"
              v-model="username"
              required
              aria-describedby="usernameHelp"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="password"
              required
              placeholder="Password"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="reenterPassword"
              v-model="password2"
              required
              placeholder="Re-enter Password"
            />
            <span v-if="password != password2" class="text-danger">Passwords must match</span>
          </div>
          <button v-on:click="register" type="submit" class="btn btn-primary">Register</button>
        </form>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "register",
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      password2: ""
    };
  },
  methods: {
    async register() {
      if (!this.firstName || !this.lastName || !this.username || !this.password) {
        alert('All fields required');
        return;
      }
      if (this.password != this.password2) {
        return;
      }
      const credentials = {
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.username,
        password: this.password
      };
      await axios.post('http://localhost:3000/api/users/register', credentials)
        .then(result => {
          this.$router.push({ name: 'Home' })
          alert(result.data.username + ' has been registered!')
          
        })
        .catch(err => {
          console.log(err)
          alert('Username already exists')
        })
    },
    submit() {}
  }
};
</script>

<style></style>
