<template>
  <div class="container">
    <br /><br /><br /><br />
    
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h1 class="display-4" style="text-align: center;">Gear Gallery</h1>
        <amplify-authenticator></amplify-authenticator>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Auth",
  components: {
    
  },
  data() {
    return {
      
    }
  },
  mounted() {
  }
}
</script>

<style>

</style>
