<template>
  <div class="container">
    <br /><br />
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h1 class="display-4" style="margin-left: 8%;">Gear Gallery</h1>
        <br /><br />
        <amplify-authenticator>
          <amplify-sign-in slot="sign-in"></amplify-sign-in>
        </amplify-authenticator>
        <!-- <form v-on:submit.prevent="submit">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="credentials.username"
              required
              aria-describedby="usernameHelp"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="credentials.password"
              required
              placeholder="Password"
            />
          </div>
          <button v-on:click="login" type="submit" class="btn btn-primary">Login</button>
        </form> -->
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "login",
  computed: {
      ...mapState(['currentUser'])
  },
  data() {
    return {
      credentials: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    async login() {
      if (!this.credentials.username || !this.credentials.password) {
        alert('Username and password required');
        return;
      }

      const user = await this.$store.dispatch('loginUser', this.credentials)

      if (user.error) {
        alert(user.error);
      } else {
        this.$router.push({ name: 'Home' })
        this.$forceUpdate();
      }
    },
    submit() {}
  },
};
</script>

<style></style>
