<template>
  <div>
    <div id="footer">
      <div class="row">
        <div class="col sm5">
          
        </div>
        <div class="col sm5" style="text-align: right;">
          <span class="navbar-text" style="padding-right: 10px;">© Ed Chase 2020</span>
        </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
#footer {
  background-color: #f8f9fa;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}
</style>
