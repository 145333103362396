import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    state: {
        currentUser: {
        email: null
        },
        signedIn: null
    },
    getters: {
        getUser: state => {return state.currentUser;},
        getStatus: state => {return state.signedIn;}
    },
    mutations: {
        SIGN_IN(state) {
            //state.currentUser.email = user.attributes.email;
            state.signedIn = true;
        },
        SIGN_OUT(state) {
            //state.currentUser.email = null;
            state.signedIn = false;
        }
    },
    actions: {
        // signIn({commit}, user) {
        //     commit('SIGN_IN', user);
        // },
        signIn({commit}) {
            commit('SIGN_IN');
        },
        signOut({commit}) {
            commit('SIGN_OUT');
        }
    }
})