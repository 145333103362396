/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Posts2-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "geargallery-uploads111440-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "postsApi",
            "endpoint": "https://xpaj4v187g.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f09277c4-23d7-43ef-82e5-50615e4bc406",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ItQSCrrz1",
    "aws_user_pools_web_client_id": "2iviihniiqj5rv6tjlvqpqqtfn",
    "oauth": {}
};


export default awsmobile;
