<template>
  <div class="row">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
      <br />
      <div
        class="searchDiv"
        style="margin-left: 25%; margin-right: 25%; border-bottom: 1px solid lightgrey; padding-left: 8%;"
      >
        <div
          class="dropdown"
          style="margin-top: 15px; margin-left: 10px; margin-bottom: 20px;"
        >
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ dropdownText }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <button
              class="dropdown-item"
              type="button"
              @click="
                searchType = 'byMakeModel';
                dropdownText = 'Make/Model';
              "
            >
              Make and/or Model
            </button>
            <button
              class="dropdown-item"
              type="button"
              @click="
                searchType = 'byTag';
                dropdownText = 'Tag';
              "
            >
              Tag
            </button>
            <button
              class="dropdown-item"
              type="button"
              @click="
                searchType = 'byUser';
                dropdownText = 'User';
              "
            >
              User
            </button>
          </div>
        </div>
        <div class="form-group w-75">
          <input
            class="form-control"
            type="search"
            placeholder="Search"
            aria-label="Search"
            v-model="searchBar"
            required
            style="margin: 10px;"
          />

          <button
            class="btn btn-outline-success my-2 my-sm-0"
            @click="searchPosts()"
            style="margin: 10px;"
          >
            Search
          </button>

          <button
            class="btn btn-outline-secondary"
            @click="
              loadVerify = 0;
              searchBar = '';
              dropdownText = 'Search by:';
              searchType = '';
            "
            style="margin: 10px;"
          >
            Reset
          </button>
        </div>
      </div>
      <br />
      <div v-if="loadVerify == 0 && checkInitPosts()" class="card-deck">
        <div v-for="post in posts" :key="post.id">
          <Card
            :card-header-text="post.user"
            :card-header-date-text="post.date"
            :card-img-src="post.image"
            :card-title-text="post.make + ' ' + post.model"
            :card-body-text="post.description"
            :card-footer-text="'Tags: ' + createTags(post.tags)"
          />
        </div>
      </div>
      <div
        v-else-if="loadVerify == 1 && filteredPosts[0].id !== ''"
        class="card-deck"
      >
        <div v-for="post in filteredPosts" :key="post.id">
          <Card
            :card-header-text="post.user"
            :card-header-date-text="post.date"
            :card-img-src="post.image"
            :card-title-text="post.make + ' ' + post.model"
            :card-body-text="post.description"
            :card-footer-text="'Tags: ' + createTags(post.tags)"
          />
        </div>
      </div>
      <span v-else class="lead" style="margin-left: 41%;"
        >No content to display</span
      >
    </div>
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "./Card";
import { Storage } from 'aws-amplify';

export default {
  name: "HomeCardList",
  components: {
    Card
  },
  data() {
    return {
      posts: [],
      filteredPosts: [
        {
          id: "",
          user: "",
          make: "",
          model: "",
          description: "",
          tags: [],
          image: "",
          date: "",
        },
      ],
      searchType: "",
      searchBar: "",
      loadVerify: 0,
      dropdownText: "Search by:",
    };
  },
  created() {
    Storage.list('')
      .then((result) => { 
        console.log(result); 
      })
      .catch((err) => { 
        console.log(err);
      });
    // await axios
    //   .get("https://xpaj4v187g.execute-api.us-east-1.amazonaws.com/dev/posts")
    //   .then((response) => {
    //     for (let i = 0; i < response.data.length; i++) {
    //       this.posts[i] = response.data[i];
    //       await Storage.get(response.data[i].image)
    //         .then((result) => { 
    //           console.log(result);
    //           this.posts[i].image = result;
    //           console.log(this.posts[i].image);
    //         })
    //         .catch((err) => { 
    //           console.log(err);
    //         });
    //     }
    //     this.$forceUpdate();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    
    // this.getPosts()
    //   .then(result => {
    //     for (let i = 0; i < result.data.length; i++) {
    //       this.posts[i] = result.data[i];
    //       console.log(this.posts[i]);
    //     }
    //     this.$forceUpdate();
    //   })
    //   console.log('test');
      
  },
  methods: {
    async getPosts() {
      return await axios
      .get("https://xpaj4v187g.execute-api.us-east-1.amazonaws.com/dev/posts");
      // .then((response) => {return response.data});


    },
    createTags(tags) {
      let tagString = "";
      tags.forEach((tag) => {
        tagString += tag + ", ";
      });
      tagString = tagString.slice(0, tagString.length - 2);
      return tagString;
    },
    searchPosts() {
      if (this.searchBar == "") {
        return;
      }
      this.filteredPosts = [
        {
          id: "",
          user: "",
          make: "",
          model: "",
          description: "",
          tags: [],
          image: "",
          date: "",
        },
      ];
      if (this.searchType == "byMakeModel") {
        let x = 0;
        for (let i = 0; i < this.posts.length; i++) {
          let makeModel =
            this.posts[i].make.toUpperCase() +
            " " +
            this.posts[i].model.toUpperCase();
          if (makeModel.includes(this.searchBar.toUpperCase())) {
            this.filteredPosts[x] = {
              id: this.posts[i].id,
              user: this.posts[i].user,
              make: this.posts[i].make,
              model: this.posts[i].model,
              description: this.posts[i].description,
              tags: this.posts[i].tags,
              image: this.posts[i].image,
              date: this.posts[i].date,
            };
            x = x + 1;
          }
        }
      } else if (this.searchType == "byTag") {
        let x = 0;
        for (let i = 0; i < this.posts.length; i++) {
          let tags = this.posts[i].tags;
          tags.forEach((tag) => {
            if (tag.toUpperCase() == this.searchBar.toUpperCase()) {
              this.filteredPosts[x] = {
                id: this.posts[i].id,
                user: this.posts[i].user,
                make: this.posts[i].make,
                model: this.posts[i].model,
                description: this.posts[i].description,
                tags: this.posts[i].tags,
                image: this.posts[i].image,
                date: this.posts[i].date,
              };
              x = x + 1;
            }
          });
        }
      } else if (this.searchType == "byUser") {
        let x = 0;
        for (let i = 0; i < this.posts.length; i++) {
          let user = this.posts[i].user.toUpperCase();
          if (user == this.searchBar.toUpperCase()) {
            this.filteredPosts[x] = {
              id: this.posts[i].id,
              user: this.posts[i].user,
              make: this.posts[i].make,
              model: this.posts[i].model,
              description: this.posts[i].description,
              tags: this.posts[i].tags,
              image: this.posts[i].image,
              date: this.posts[i].date,
            };
            x = x + 1;
          }
        }
      } else {
        let x = 0;
        for (let i = 0; i < this.posts.length; i++) {
          let makeModel =
            this.posts[i].make.toUpperCase() +
            " " +
            this.posts[i].model.toUpperCase();
          let tags = this.posts[i].tags;
          let user = this.posts[i].user.toUpperCase();
          if (
            makeModel.includes(this.searchBar.toUpperCase()) ||
            user == this.searchBar.toUpperCase()
          ) {
            this.filteredPosts[x] = {
              id: this.posts[i].id,
              user: this.posts[i].user,
              make: this.posts[i].make,
              model: this.posts[i].model,
              description: this.posts[i].description,
              tags: this.posts[i].tags,
              image: this.posts[i].image,
              date: this.posts[i].date
            };
            x = x + 1;
          } else {
            tags.forEach((tag) => {
              if (tag.toUpperCase() == this.searchBar.toUpperCase()) {
                this.filteredPosts[x] = {
                  id: this.posts[i].id,
                  user: this.posts[i].user,
                  make: this.posts[i].make,
                  model: this.posts[i].model,
                  description: this.posts[i].description,
                  tags: this.posts[i].tags,
                  image: this.posts[i].image,
                  date: this.posts[i].date
                };
                x = x + 1;
              }
            });
          }
        }
      }
      if (this.filteredPosts.length > 0) {
        this.loadVerify = 1;
      }
    },
    checkInitPosts() {
      try {
        if (this.posts[0].id !== "") {
          return true;
        }
      } catch (err) {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
