<template>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <br />
      <div
        class="profile-info"
        style="margin-left: 20%; margin-right: 20%; border-bottom: 1px solid lightgrey; padding-left: 15%; padding-bottom: 35px;"
      >
        <h1 class="display-4">Profile Information</h1>
        <p>
          Username: {{ currentUser.username }}<br />
          First Name: {{ currentUser.firstName }}<br />
          Last Name: {{ currentUser.lastName }}
        </p>
        <div v-if="!changePasswordCheck" class="buttons">
          <button
            type="button"
            class="btn btn-primary"
            style="margin-right: 15px"
            @click="changePasswordCheck = true"
          >
            Change Password
          </button>
          <button type="button" class="btn btn-danger" @click="deleteProfile">
            Delete Account
          </button>
        </div>
        <form class="form-group w-75" v-if="changePasswordCheck" v-on:submit.prevent="submit">
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="oldPassword"
              v-model="oldPassword"
              required
              placeholder="Current Password"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="newPassword"
              v-model="newPassword"
              required
              placeholder="New Password"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="reenterPassword"
              v-model="newPassword2"
              required
              placeholder="Re-enter Password"
            />
          </div>
          <span v-if="newPassword != newPassword2" class="text-danger"
            >Passwords must match</span
          >
          <button
            v-on:click="changePassword()"
            type="submit"
            class="btn btn-primary"
          >
            Confirm
          </button>
          <button
            v-on:click="cancelPasswordChange()"
            type="cancel"
            class="btn btn-secondary"
            style="margin-left: 15px;"
          >
            Cancel
          </button>
        </form>
      </div>
      <br />
      <ProfileCardList />
    </div>
    <div class="col-md-2"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ProfileCardList from "../components/ProfileCardList.vue";
export default {
  name: "Profile",
  components: {
    ProfileCardList,
  },
  computed: {
    ...mapState(["currentUser"]),
  },
  mounted() {},
  data() {
    return {
      profile: {
        username: "",
        firstName: "",
        lastName: "",
      },
      changePasswordCheck: false,
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
    };
  },
  methods: {
    async deleteProfile() {
      const answer = window.confirm(
        "Are you sure you want to delete this profile?"
      );
      if (answer) {
        let urlString =
          "http://localhost:3000/api/users/" + this.currentUser.username;
        await axios
          .delete(urlString)
          .then(() => {
            urlString =
              "http://localhost:3000/api/posts/delete_by_user/" +
              this.currentUser.username;
            axios.delete(urlString);
            this.$store.dispatch("logoutUser");
            alert("User deleted");
            this.$router.push({ name: "Home" });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    async changePassword() {
      if (!this.oldPassword || !this.newPassword || !this.newPassword2) {
        alert("All fields required");
        return;
      }
      if (this.newPassword != this.newPassword2) {
        return;
      }
      let credentials = {
        username: this.currentUser.username,
        password: this.oldPassword,
      };
      const response = await axios.post(
        "http://localhost:3000/api/users/login",
        credentials
      );
      const user = response.data;
      if (!user) {
        alert("Old password is incorrect");
        this.cancelPasswordChange();
        return;
      } else {
        const stringURL =
          "http://localhost:3000/api/users/update/" +
          this.currentUser.username.toString();
        credentials = {
          password: this.newPassword,
        };
        await axios
          .patch(stringURL, credentials)
          .then(() => {
            alert("Password changed successfully");
            this.cancelPasswordChange();
            return;
          })
          .catch((err) => {
            console.log(err);
            this.cancelPasswordChange();
            return;
          });
      }
    },
    cancelPasswordChange() {
      this.changePasswordCheck = false;
      this.$forceUpdate();
      return;
    },
    submit() {},
  },
};
</script>

<style></style>
